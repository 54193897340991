.App {
  text-align: center;
  background-color: "#FFF";
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #FFF;
  
 
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(90deg, rgba(205,65,183,1) 0%, rgba(205,65,101,1) 33%, rgba(229,116,79,1) 66%, rgba(255,226,111,1) 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
